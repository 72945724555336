.summaryWrap {
  ul {
    list-style-type: disc;
  }
  li {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ::marker {
    font-size: 12px;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
  }
}

.right_animation {
  animation: fadeInRight 1s ease-in-out;
}

@keyframes fadeInUp {
  from {
  opacity: 0;
  -ms-transform: translateY(50%);
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50px);
  }
  to {
  opacity: 1;
  }
  }
  
  .up_animation {
  animation: fadeInUp 1s ease-in-out;
  }

.collapse{
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.collapse.show{
  height: auto;
}





@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .feed_container {
    position: fixed;
    overscroll-behavior: contain;
  }
}

.feed {
  -webkit-transition-property: top; /* Chrome 1-25, Safari 3.2+ */
}

@-webkit-keyframes appearTop {
  from {
    top: -16px;
  }

  to {
    top: 4px;
  }
}

.storiesPopup{
  width: 332px;
  position: relative;
  text-align: center;
  z-index: 9999;    
  position: absolute;
  top: 267px;

}

.articleEducationPopup{
  width: 332px;
  position: relative;
  text-align: center;
  z-index: 9999;    
  position: absolute;
  top: 124px;

}

.storiesPopup:after {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
}

.storiesPopup.arrowTop:before {
  left: calc(50% - 156px);
  top: -8px;
}

.articleEducationPopup.arrowBottom:before {
  left: calc(50% - 24px);
  bottom: -8px;
}

.storiesPopup:before, .articleEducationPopup:before {
  content: "";
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
  // background: #fff;
  position: absolute;
  z-index: 998;
  left:24px
}

.popupWrapper {
  width: 100%;
  padding: 22px;
  border-radius: 4px;
  background: #fff;
  position: relative;
  z-index: 998;  
}

@keyframes appearTop {
  from {
    top: -16px;
  }

  to {
    top: 4px;
  }
}

@-webkit-keyframes appearTopExtended {
  from {
    top: -16px;
  }

  to {
    top: 6px;
  }
}

@keyframes appearTopExtended {
  from {
    top: -16px;
  }

  to {
    top: 6px;
  }
}

@-webkit-keyframes exitTopExtended {
  from {
    top: 44px;
    opacity: 1;
  }

  to {
    top: -72px;
    opacity: 0;
  }
}

@keyframes exitTopExtended {
  from {
    top: 44px;
  }

  to {
    top: -72px;
  }
}

@-webkit-keyframes exitTop {
  from {
    top: 4px;
  }

  to {
    top: -56px;
  }
}

@keyframes exitTop {
  from {
    top: 4px;
  }

  to {
    top: -56px;
  }
}

.storyCard::after{
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient( 180deg, rgba(0, 0, 0, 0.0001) 49.88%, rgba(0, 0, 0, 0.9) 100% );
  height: 100%;
  width: 100%;
  position: absolute;
  content: "";
}


.circles {
  height: 90vmin;
  position: relative;
  width: 90vmin;
  
  > div {
    animation: growAndFade 3s infinite ease-out;
    background-color:#000000;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
  
  .circle1 {
    animation-delay: 1s;    
  }
  .circle2 {
    animation-delay: 2s; 
  }
  .circle3 {
    animation-delay: 3s;
  }
}

@keyframes growAndFade {
  0% {
    opacity: .25;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}


/* Zooming entrances */
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}


.storyZoomIn {
  // -webkit-animation-name: zoomIn;
  // animation-name: zoomIn;
  // -webkit-animation-duration: 500ms;
  top:0;
  left: 0;
  animation-duration: 5000ms;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.storyZoomOut {
  // -webkit-animation-name: zoomOut;
  // animation-name: zoomOut;
  // -webkit-animation-duration: 500ms;
  animation-duration: 5000ms;
  height: 0px;
  width: 0px;
  top: 157px;
  left: 57px;
  opacity: 0;
}


/* Zooming exits */
@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.animationContainer {
  width: 100%;
  height: 192px;
  position: absolute;
  z-index: 999999;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.arrows {
  width: 60px;
  height: 92px;
  // position: absolute;
  // left: 50%;
  // margin-left: -30px;
  // bottom: 70px;
  transform: rotate(180deg);
  // z-index: 999999;
  // max-width: 480px;
}

.arrows path {
  stroke: #F94929;
  fill: transparent;
  stroke-width: 6px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 { 
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}
